// External packages
import * as React from "react"
import { createPortal } from "react-dom"
import { Box, Paragraph, Flex, Image } from "theme-ui"

// Hooks
import { useProducts } from "medusa-react"
import { useStore } from "../../context/NewStoreContext"
import { useNotificationContext } from "../../context/NotificationContext"
import { useInterfaceContext } from "../../context/InterfaceContext"

// Utilities
import { formatProductPrice } from "../../utils/formatProductPrice"
import { getProductTitleWithColor } from "../../utils/getProductTitleWithColor"
import { handleAddToCart } from "../../components/v2/utils/product/handleAddToCart"
import { isVariantOutOfStock } from "../../components/v2/utils/product/isVariantOutOfStock"
import { isProductOutOfStock } from "../../components/v2/utils/product/isProductOutOfStock"
import { getVariantsInventory } from "../../components/v2/utils/product/getVariantsInventory"

// Components
import { Drawer } from "../../components/v2/ui/Drawer"
import { SizePickerDrawer } from "../../components/v2/product/SizePickerDrawer"
import { ProductButton } from "../../components/v2/product/ProductButton"
import { Button } from "../../components/v2/Button"
import { NotifyMeDrawer } from "../../components/v2/product/NotifyMeDrawer"
import { Link } from "./Link"

// Types
import { ContentfulWidget } from "gatsby-node"

type WidgetProducts = ContentfulWidget["products"]

export const FeaturedProductsDrawer = ({
  isOpened,
  products,
  isFeaturedProductsSizeDrawerOpened,
  setIsFeaturedProductsSizeDrawerOpened,
  setLocked,
  onCloseClick,
  onBackdropClick,
}: {
  isOpened: boolean
  products: WidgetProducts
  isFeaturedProductsSizeDrawerOpened: boolean
  setIsFeaturedProductsSizeDrawerOpened: (value: boolean) => void
  setLocked: (value: boolean) => void
  onCloseClick: () => void
  onBackdropClick?: () => void
}) => {
  const { cart, createLineItem } = useStore()
  const [selectedVariant, setSelectedVariant] = React.useState(null)
  const [isNotifyMeOpen, setIsNotifyMeOpen] = React.useState(false)
  const [openedProduct, setOpenedProduct] = React.useState(products[0])

  const {
    products: MedusaProducts,
    isLoading: MedusaProductLoading,
    isSuccess: MedusaProductsLoaded,
  } = useProducts(
    {
      id: products.map((product) => product.objectId),
      cart_id: cart?.id,
      region_id: cart?.region_id,
      currency_code: cart?.region?.currency_code,
      expand: "color,type,variants,variants.prices,categories",
    },
    {
      enabled: isOpened,
      keepPreviousData: true,
      onError: (error) => {
        console.log(error)
      },
    }
  )

  const productsData = React.useMemo(() => {
    if (!MedusaProducts?.length) {
      return []
    }

    return products?.map((contentfulProduct) => {
      const product = MedusaProducts?.find(
        (p) => contentfulProduct.objectId === p.id
      )

      if (!product?.id) {
        return null
      }

      return {
        id: product?.id,
        title: getProductTitleWithColor(product),
        price: formatProductPrice(product, cart?.region?.currency_code),
        image:
          contentfulProduct?.imagePacks?.[0]?.featuredImage?.gatsbyImageData,
        variants: product.variants,
        disableNotifyMe: contentfulProduct?.disableNotifyMe,
        categories: product.categories,
        type: product.type,
        sku: contentfulProduct?.sku,
        size_guides: contentfulProduct?.size_guides,
        url: `/product/${product.handle}`,
        primaryCategory: product?.categories?.find(
          (i) => i.id === product.primary_category_id
        )?.name,
      }
    })
  }, [products, MedusaProducts])

  const { pushNotification, dismissNotification } = useNotificationContext()
  const { showCart } = useInterfaceContext()

  const onAddToCart = (notificationContent) => {
    pushNotification({
      id: "add-to-cart",
      body: (
        <>
          <Paragraph sx={{ fontSize: "lg", marginBlockEnd: 4 }}>
            Added to cart
          </Paragraph>
          <Paragraph sx={{ marginBlockEnd: 4 }}>
            {notificationContent}
          </Paragraph>
          <Button
            sx={{ width: "100%", marginBlockStart: 6 }}
            onClick={() => {
              dismissNotification("add-to-cart")
              showCart()
            }}
          >
            See the cart
          </Button>
        </>
      ),
      dismiss: {
        duration: 3000,
      },
    })
  }

  return (
    <>
      <Drawer
        isOpened={isOpened}
        onCloseClick={() => onCloseClick()}
        onBackdropClick={() => onBackdropClick()}
        onSwipeRight={() => onCloseClick()}
      >
        <Flex
          sx={{
            height: "100%",
            flexDirection: "column",
            alignItems: "center",
            gap: 4,
          }}
        >
          {productsData?.map(
            (product) =>
              !isProductOutOfStock(getVariantsInventory(product)) && (
                <Flex
                  key={product?.id}
                  sx={{
                    alignItems: "center",
                    gap: [6, 10],
                    width: "100%",
                    maxWidth: [null, "364px"],
                    ".item-button": {
                      visibility: [null, "hidden"],
                      opacity: [null, 0],
                      transition: "visibility .2s, opacity .2s",
                    },
                    "&:hover .item-button": {
                      visibility: "visible",
                      opacity: 1,
                    },
                    ".item-content": {
                      transform: [null, "translateY(36px)"],
                      transition: "transform .2s",
                    },
                    "&:hover .item-content": {
                      transform: "translateY(0)",
                    },
                  }}
                >
                  {product?.image ? (
                    <Link to={product?.url} sx={{ flexShrink: 0 }}>
                      <Box as="picture" sx={{ flexShrink: 0 }}>
                        {product?.image?.images.sources.map((source, index) => (
                          <source
                            key={index}
                            type={source.type}
                            srcSet={source.srcSet}
                            sizes={source.sizes}
                          />
                        ))}
                        <Image
                          width={product?.image?.width}
                          height={product?.image?.height}
                          sizes={product?.image?.images.fallback.sizes}
                          decoding="async"
                          loading="lazy"
                          src={product?.image?.images.fallback.src}
                          srcSet={product?.image?.images.fallback.srcSet}
                          alt={product?.title}
                          sx={{
                            display: "block",
                            width: 35,
                            height: "100%",
                            objectFit: "cover",
                            transform: "translateZ(0)",
                          }}
                        />
                      </Box>
                    </Link>
                  ) : null}
                  <Flex
                    className="item-content"
                    sx={{
                      flexDirection: "column",
                      flexGrow: 1,
                      gap: 2,
                      fontSize: "sm",
                    }}
                  >
                    <Paragraph
                      sx={{
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                      }}
                    >
                      {product.title}
                    </Paragraph>
                    <Paragraph>{product.price}</Paragraph>
                    <Box sx={{ marginBlockStart: 4 }} className="item-button">
                      {product?.variants.length < 2 ? (
                        <ProductButton
                          sx={{ width: "100%" }}
                          hasNotifyMe={false}
                          isProductSoldOut={
                            isProductOutOfStock(
                              getVariantsInventory(product)
                            ) && Boolean(product?.disableNotifyMe)
                          }
                          isProductOutOfStock={isProductOutOfStock(
                            getVariantsInventory(product)
                          )}
                          isVariantSelected={Boolean(product?.variants[0]?.id)}
                          isVariantOutOfStock={isVariantOutOfStock(
                            product?.variants[0]
                          )}
                          isLoading={
                            createLineItem.isLoading || !MedusaProductsLoaded
                          }
                          isVisuallyDisabled={
                            createLineItem.isLoading || !MedusaProductsLoaded
                          }
                          isVariantSoldOut={
                            Boolean(product?.disableNotifyMe) &&
                            isVariantOutOfStock(product?.variants[0])
                          }
                          sizePickerVariant="single"
                          isGiftcard={false}
                          isMobile={false}
                          isInDrawer={false}
                          isProductRelated={false}
                          isProductSingleSize={product?.variants?.length < 2}
                          onClick={() => {
                            if (
                              !Boolean(product?.disableNotifyMe) &&
                              isVariantOutOfStock(product?.variants[0])
                            ) {
                              return
                            }

                            if (
                              (isProductOutOfStock(
                                getVariantsInventory(product)
                              ) &&
                                Boolean(product?.disableNotifyMe)) ||
                              (Boolean(product?.disableNotifyMe) &&
                                isVariantOutOfStock(product?.variants[0]))
                            ) {
                              return
                            }

                            onCloseClick()

                            handleAddToCart({
                              product: {
                                title: product.title,
                                subcategory: product.primaryCategory,
                              },
                              variant: {
                                ...product.variants[0],
                                quantity: 1,
                              },
                              cart: cart,
                              createLineItem: createLineItem,
                              onAddToCart: onAddToCart,
                            })
                          }}
                        />
                      ) : (
                        <Button
                          variant="ghost"
                          sx={{ width: "100%" }}
                          onClick={() => {
                            setIsFeaturedProductsSizeDrawerOpened(true)
                            setOpenedProduct(product)
                          }}
                        >
                          Select size
                        </Button>
                      )}
                    </Box>
                  </Flex>
                </Flex>
              )
          )}
          {typeof window !== "undefined" ? (
            <>
              {createPortal(
                <SizePickerDrawer
                  variants={openedProduct?.variants}
                  product={openedProduct}
                  drawerProps={{
                    isOpened: isFeaturedProductsSizeDrawerOpened,
                    hasBlur: false,
                    onCloseClick: () => {
                      setIsFeaturedProductsSizeDrawerOpened(false)
                    },
                    onBackdropClick: () => onBackdropClick(),
                    onSwipeRight: () => {
                      setIsFeaturedProductsSizeDrawerOpened(false)
                    },
                    sx: { backgroundColor: "grayscale.white" },
                  }}
                  onAddToCart={onAddToCart}
                  onNotifyMeClick={(variant) => {
                    setIsNotifyMeOpen(true)
                    setSelectedVariant(variant)
                    setLocked(true)
                  }}
                  onSizeGuideCloseClick={() => {
                    setIsFeaturedProductsSizeDrawerOpened(false)
                  }}
                  onSizeGuideBackdropClick={() => onBackdropClick()}
                />,
                document.getElementById("drawers-root")
              )}
              {createPortal(
                <NotifyMeDrawer
                  isOpened={isNotifyMeOpen}
                  isNotifyOpenedFromDrawer={true}
                  selectedVariant={selectedVariant}
                  onCloseClick={() => setIsNotifyMeOpen(false)}
                  onBackdropClick={() => {
                    setIsNotifyMeOpen(false)
                    onBackdropClick()
                  }}
                />,
                document.getElementById("drawers-root")
              )}
            </>
          ) : null}
        </Flex>
      </Drawer>
    </>
  )
}
